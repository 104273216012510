<template>
  <div class="row">
    <div class="col-sm-12">
      <KTPortlet v-bind:class="'kt-portlet--height-fluid'" v-bind="{ bodyFit: true }">
        <template v-slot:body>
          <div class="kt-widget14" style="padding: 15px">
            <div class="kt-widget14__header" style="margin: 0; padding: 0">
              <div class="widget-header d-flex">
                <div class="widget-header-title">
                  <h5 class="text-primary mb-1">Deposit Transactions</h5>
                </div>
                <div class="widget-header-toolbar d-flex">
                  <div class="form-group">
                    <div class="input-icon input-icon-sm">
                      <b-input
                        type="text"
                        class="form-control"
                        placeholder="Search phone..."
                        v-model="deposit.user"
                        @input="getDepositData"
                      />
                      <span>
                        <i class="flaticon2-search-1 icon-md"></i>
                      </span>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="input-icon input-icon-sm">
                      <datePicker
                        v-model="deposit.filterDate"
                        :config="dpOptions"
                        @dp-change="getDepositData"
                      ></datePicker>
                      <span>
                        <i class="flaticon-event-calendar-symbol"></i>
                      </span>
                    </div>
                  </div>
                  <b-dropdown
                    block
                    id="deposit-status"
                    size="sm"
                    text="Status"
                    variant="outline-success"
                    class="text-success"
                  >
                    <b-dropdown-item
                      v-for="item in deposit.statusList"
                      :active="activeDepositStatus(item)"
                      :key="'deposit_status_' + item.value"
                      @click="changeDepositStatus(item)"
                      >{{ item.text }}</b-dropdown-item
                    >
                  </b-dropdown>
                  <div class="text-dark">
                    <b-button block class="btn btn-outline-dark btn-sm" @click="getDepositData">
                      <i class="fas fa-undo" style="margin-right: rem" />Refresh
                    </b-button>
                  </div>
                  <div class="text-primary">
                    <b-button block class="btn btn-outline-primary btn-sm">
                      <i class="far fa-arrow-alt-circle-down" style="margin-right: rem" />Export
                    </b-button>
                  </div>
                </div>
              </div>
              <b-table
                striped
                :fields="deposit.headers"
                :items="deposit.items"
                :busy="deposit.busy"
                responsive
                bordered
                show-empty
                class="elevation-1 data-table text-center orderTable mb-0"
              >
                <template v-slot:cell(total_payment)="item">{{ formatPrice(item.item.total_payment) }}</template>
                <template v-slot:cell(status)="item">
                  <b-badge class="mr-1" v-if="item.value === 1" variant="success">Success</b-badge>
                  <b-badge class="mr-1" v-else-if="item.value === 2" variant="danger">Rejected</b-badge>
                  <b-badge class="mr-1" v-else-if="item.value === 0" variant="warning">Pending</b-badge>
                </template>
                <template v-slot:cell(actions)="item">
                  <v-icon
                    class="text-success"
                    :id="'retry_' + item.item.id"
                    @click="retryItem(item.item)"
                    :disabled="item.item.status == 1"
                    >flaticon-refresh</v-icon
                  >
                  <b-tooltip :target="'retry_' + item.item.id" triggers="hover">Retry</b-tooltip>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>&nbsp;Loading...</strong>
                  </div>
                </template>
              </b-table>
              <b-row class="mt-2">
                <b-col cols="5">
                  Showing {{ (deposit.currentPage - 1) * deposit.perPage + 1 }}-{{
                    deposit.currentPage * deposit.perPage > deposit.rows
                      ? deposit.rows
                      : deposit.currentPage * deposit.perPage
                  }}
                  of {{ deposit.rows }}.
                </b-col>
                <b-col style="display: flex; justify-content: flex-end">
                  <b-form inline id="deposit-input-per-page" style="margin-right: 20px; margin-bottom: 0">
                    <label for="per-page" class="mr-sm-2">Rows per page:</label>
                    <b-form-select
                      id="deposit-per-page"
                      v-model="deposit.perPage"
                      size="sm"
                      :options="[5, 10, 15, 20]"
                      @change="getDepositData"
                    ></b-form-select>
                  </b-form>
                  <b-pagination
                    v-model="deposit.currentPage"
                    :total-rows="deposit.rows"
                    :per-page="deposit.perPage"
                    aria-controls="my-table"
                    align="right"
                    v-on:input="getDepositData"
                  ></b-pagination>
                </b-col>
              </b-row>
            </div>
          </div>
        </template>
      </KTPortlet>
    </div>
    <div class="col-sm-12">
      <KTPortlet v-bind:class="'kt-portlet--height-fluid'" v-bind="{ bodyFit: true }">
        <template v-slot:body>
          <div class="kt-widget14" style="padding: 15px">
            <div class="kt-widget14__header" style="margin: 0; padding: 0">
              <div class="widget-header d-flex">
                <div class="widget-header-title">
                  <h5 class="text-primary mb-1">Momo Transactions</h5>
                </div>
                <div class="widget-header-toolbar d-flex">
                  <div class="form-group">
                    <div class="input-icon input-icon-sm">
                      <b-input
                        type="text"
                        class="form-control"
                        placeholder="Search phone..."
                        v-model="momo.user"
                        @input="getMomoData"
                        debounce="1000"
                      />
                      <span>
                        <i class="flaticon2-search-1 icon-md"></i>
                      </span>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="input-icon input-icon-sm">
                      <datePicker v-model="momo.filterDate" :config="dpOptions" @dp-change="getMomoData"></datePicker>
                      <span>
                        <i class="flaticon-event-calendar-symbol"></i>
                      </span>
                    </div>
                  </div>
                  <b-dropdown
                    block
                    id="momo-status"
                    size="sm"
                    text="Status"
                    variant="outline-success"
                    class="text-success"
                  >
                    <b-dropdown-item
                      v-for="item in momo.statusList"
                      :active="activeMomoStatus(item)"
                      :key="'momo_status_' + item.value"
                      @click="changeMomoStatus(item)"
                      >{{ item.text }}</b-dropdown-item
                    >
                  </b-dropdown>
                  <div class="text-dark">
                    <b-button block class="btn btn-outline-dark btn-sm" @click="getMomoData">
                      <i class="fas fa-undo" style="margin-right: rem" />Refresh
                    </b-button>
                  </div>
                  <div class="text-primary">
                    <b-button block class="btn btn-outline-primary btn-sm">
                      <i class="far fa-arrow-alt-circle-down" style="margin-right: rem" />Export
                    </b-button>
                  </div>
                </div>
              </div>
              <b-table
                striped
                :fields="momo.headers"
                :items="momo.items"
                :busy="momo.busy"
                responsive
                bordered
                show-empty
                class="elevation-1 data-table text-center orderTable mb-0"
              >
                <template v-slot:cell(message)="item">{{ item.item.payment_status == 2 ? item.value : '' }}</template>
                <template v-slot:cell(amount)="item">{{ formatPrice(item.item.amount) }}</template>
                <template v-slot:cell(payment_status)="item">
                  <b-badge class="mr-1" v-if="item.value === 1" variant="success">Success</b-badge>
                  <b-badge class="mr-1" v-else-if="item.value === 2" variant="danger">Failed</b-badge>
                  <b-badge class="mr-1" v-if="item.item.status === 1" variant="info">Refunded</b-badge>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>&nbsp;Loading...</strong>
                  </div>
                </template>
              </b-table>
              <b-row class="mt-2">
                <b-col cols="5">
                  Showing {{ (momo.currentPage - 1) * momo.perPage + 1 }}-{{
                    momo.currentPage * momo.perPage > momo.rows ? momo.rows : momo.currentPage * momo.perPage
                  }}
                  of {{ momo.rows }}.
                </b-col>
                <b-col style="display: flex; justify-content: flex-end">
                  <b-form inline id="momo-input-per-page" style="margin-right: 20px; margin-bottom: 0">
                    <label for="per-page" class="mr-sm-2">Rows per page:</label>
                    <b-form-select
                      id="momo-per-page"
                      v-model="momo.perPage"
                      size="sm"
                      :options="[5, 10, 15, 20]"
                      @change="getMomoData"
                    ></b-form-select>
                  </b-form>
                  <b-pagination
                    v-model="momo.currentPage"
                    :total-rows="momo.rows"
                    :per-page="momo.perPage"
                    aria-controls="my-table"
                    align="right"
                    v-on:input="getMomoData"
                  ></b-pagination>
                </b-col>
              </b-row>
            </div>
          </div>
        </template>
      </KTPortlet>
    </div>
    <div class="col-sm-12">
      <KTPortlet v-bind:class="'kt-portlet--height-fluid'" v-bind="{ bodyFit: true }">
        <template v-slot:body>
          <div class="kt-widget14" style="padding: 15px">
            <div class="kt-widget14__header" style="margin: 0; padding: 0">
              <div class="widget-header d-flex">
                <div class="widget-header-title">
                  <h5 class="text-primary mb-1">Withdraw Transactions</h5>
                </div>
                <div class="widget-header-toolbar d-flex">
                  <div class="form-group">
                    <div class="input-icon input-icon-sm">
                      <b-input
                        type="text"
                        class="form-control"
                        placeholder="Search phone..."
                        v-model="withdraw.user"
                        @input="getWithdrawData"
                      />
                      <span>
                        <i class="flaticon2-search-1 icon-md"></i>
                      </span>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="input-icon input-icon-sm">
                      <datePicker
                        v-model="withdraw.filterDate"
                        :config="dpOptions"
                        @dp-change="getWithdrawData"
                      ></datePicker>
                      <span>
                        <i class="flaticon-event-calendar-symbol"></i>
                      </span>
                    </div>
                  </div>
                  <b-dropdown
                    block
                    id="withdraw-status"
                    size="sm"
                    text="Status"
                    variant="outline-success"
                    class="text-success"
                  >
                    <b-dropdown-item
                      v-for="item in withdraw.statusList"
                      :active="activeWithdrawStatus(item)"
                      :key="'withdraw_status_' + item.value"
                      @click="changeWithdrawStatus(item)"
                      >{{ item.text }}</b-dropdown-item
                    >
                  </b-dropdown>
                  <div class="text-dark">
                    <b-button block class="btn btn-outline-dark btn-sm" @click="getWithdrawData">
                      <i class="fas fa-undo" style="margin-right: rem" />Refresh
                    </b-button>
                  </div>
                  <div class="text-primary">
                    <b-button block class="btn btn-outline-primary btn-sm">
                      <i class="far fa-arrow-alt-circle-down" style="margin-right: rem" />Export
                    </b-button>
                  </div>
                </div>
              </div>
              <b-table
                striped
                :fields="withdraw.headers"
                :items="withdraw.items"
                :busy="withdraw.busy"
                responsive
                bordered
                show-empty
                class="elevation-1 data-table text-center orderTable mb-0"
              >
                <template v-slot:cell(withdraw_amount)="item">{{ formatPrice(item.item.withdraw_amount) }}</template>
                <template v-slot:cell(withdraw_info)="item">
                  <p class="mb-1">
                    Bank name: <b>{{ item.value.bank_name }}</b>
                  </p>
                  <p class="mb-1">
                    Bank number: <b>{{ item.value.bank_account_number }}</b>
                  </p>
                  <p class="mb-1">
                    Account name: <b>{{ item.value.bank_account_name }}</b>
                  </p>
                  <p class="mb-1">
                    Bank branch: <b>{{ item.value.bank_branch }}</b>
                  </p>
                  <p class="mb-1">
                    Province name: <b>{{ item.value.province_name }}</b>
                  </p>
                </template>
                <template v-slot:cell(status)="item">
                  <b-badge class="mr-1" v-if="item.value === 1" variant="success">Success</b-badge>
                  <b-badge class="mr-1" v-else-if="item.value === 2" variant="danger">Rejected</b-badge>
                  <b-badge class="mr-1" v-else-if="item.value === 0" variant="warning">Pending</b-badge>
                </template>
                <template v-slot:cell(actions)="item">
                  <span class="action-btn mr-2" @click="approveWithdraw(item.item)" :id="'edit_' + item.item.id"
                    ><i class="flaticon2-checkmark text-success icon-md"></i>
                  </span>
                  <span class="action-btn mr-2" @click="rejectWithdraw(item.item)" :id="'delete_' + item.item.id"
                    ><i class="flaticon2-trash text-danger icon-md"></i>
                  </span>
                  <b-tooltip :target="'edit_' + item.item.id" triggers="hover">Approve</b-tooltip>
                  <b-tooltip :target="'delete_' + item.item.id" triggers="hover">Reject</b-tooltip>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>&nbsp;Loading...</strong>
                  </div>
                </template>
              </b-table>
              <b-row class="mt-2">
                <b-col cols="5">
                  Showing {{ (withdraw.currentPage - 1) * withdraw.perPage + 1 }}-{{
                    withdraw.currentPage * withdraw.perPage > withdraw.rows
                      ? withdraw.rows
                      : withdraw.currentPage * withdraw.perPage
                  }}
                  of {{ withdraw.rows }}.
                </b-col>
                <b-col style="display: flex; justify-content: flex-end">
                  <b-form inline id="withdraw-input-per-page" style="margin-right: 20px; margin-bottom: 0">
                    <label for="per-page" class="mr-sm-2">Rows per page:</label>
                    <b-form-select
                      id="withdraw-per-page"
                      v-model="withdraw.perPage"
                      size="sm"
                      :options="[5, 10, 15, 20]"
                      @change="getWithdrawData"
                    ></b-form-select>
                  </b-form>
                  <b-pagination
                    v-model="withdraw.currentPage"
                    :total-rows="withdraw.rows"
                    :per-page="withdraw.perPage"
                    aria-controls="my-table"
                    align="right"
                    v-on:input="getWithdrawData"
                  ></b-pagination>
                </b-col>
              </b-row>
            </div>
          </div>
        </template>
      </KTPortlet>
    </div>
    <div class="col-sm-12">
      <KTPortlet v-bind:class="'kt-portlet--height-fluid'" v-bind="{ bodyFit: true }">
        <template v-slot:body>
          <div class="kt-widget14" style="padding: 15px">
            <div class="kt-widget14__header" style="margin: 0; padding: 0">
              <div class="widget-header d-flex">
                <div class="widget-header-title">
                  <h5 class="text-primary mb-1">Rewards</h5>
                </div>
                <div class="widget-header-toolbar d-flex">
                  <div class="form-group">
                    <div class="input-icon input-icon-sm">
                      <b-input
                        type="text"
                        class="form-control"
                        placeholder="Search phone..."
                        v-model="reward.user"
                        @input="getRewardData"
                      />
                      <span>
                        <i class="flaticon2-search-1 icon-md"></i>
                      </span>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="input-icon input-icon-sm">
                      <datePicker
                        v-model="reward.filterDate"
                        :config="dpOptions"
                        @dp-change="getRewardData"
                      ></datePicker>
                      <span>
                        <i class="flaticon-event-calendar-symbol"></i>
                      </span>
                    </div>
                  </div>
                  <b-dropdown
                    block
                    id="reward-status"
                    size="sm"
                    text="Status"
                    variant="outline-success"
                    class="text-success"
                  >
                    <b-dropdown-item
                      v-for="item in reward.statusList"
                      :active="activeRewardStatus(item)"
                      :key="'reward_status_' + item.value"
                      @click="changeRewardStatus(item)"
                      >{{ item.text }}</b-dropdown-item
                    >
                  </b-dropdown>
                  <div class="text-dark">
                    <b-button block class="btn btn-outline-dark btn-sm" @click="getRewardData">
                      <i class="fas fa-undo" style="margin-right: rem" />Refresh
                    </b-button>
                  </div>
                  <div class="text-primary">
                    <b-button block class="btn btn-outline-primary btn-sm">
                      <i class="far fa-arrow-alt-circle-down" style="margin-right: rem" />Export
                    </b-button>
                  </div>
                </div>
              </div>
              <b-table
                striped
                :fields="reward.headers"
                :items="reward.items"
                :busy="reward.busy"
                responsive
                bordered
                show-empty
                class="elevation-1 data-table text-center orderTable mb-0"
              >
                <template v-slot:cell(reward_amount)="item">{{ formatPrice(item.value) }}</template>
                <template v-slot:cell(status)="item">
                  <b-badge class="mr-1" v-if="item.value === 1" variant="success">Paid</b-badge>
                  <b-badge class="mr-1" v-else-if="item.value === 2" variant="danger">Rejected</b-badge>
                  <b-badge class="mr-1" v-else-if="item.value === 0" variant="warning">Pending</b-badge>
                  <b-badge class="mr-1" v-if="item.item.transfer_status === 1" variant="info">Transfered</b-badge>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>&nbsp;Loading...</strong>
                  </div>
                </template>
              </b-table>
              <b-row class="mt-2">
                <b-col cols="5">
                  Showing {{ (reward.currentPage - 1) * reward.perPage + 1 }}-{{
                    reward.currentPage * reward.perPage > reward.rows
                      ? reward.rows
                      : reward.currentPage * reward.perPage
                  }}
                  of {{ reward.rows }}.
                </b-col>
                <b-col style="display: flex; justify-content: flex-end">
                  <b-form inline id="reward-input-per-page" style="margin-right: 20px; margin-bottom: 0">
                    <label for="per-page" class="mr-sm-2">Rows per page:</label>
                    <b-form-select
                      id="reward-per-page"
                      v-model="reward.perPage"
                      size="sm"
                      :options="[5, 10, 15, 20]"
                      @change="getRewardData"
                    ></b-form-select>
                  </b-form>
                  <b-pagination
                    v-model="reward.currentPage"
                    :total-rows="reward.rows"
                    :per-page="reward.perPage"
                    aria-controls="my-table"
                    align="right"
                    v-on:input="getRewardData"
                  ></b-pagination>
                </b-col>
              </b-row>
            </div>
          </div>
        </template>
      </KTPortlet>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import KTPortlet from '@/views/partials/content/Portlet.vue';
import { mapGetters } from 'vuex';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import ApiService from '@/common/api.service.js';
import { formatDateForView, formatDateV2 } from '@/utils/time.js';
import Swal from 'sweetalert2';
export default {
  name: 'customer_supports',
  components: {
    KTPortlet,
    datePicker
  },
  data() {
    return {
      deposit: {
        headers: [
          { label: 'Username', key: 'username' },
          { label: 'Created At', key: 'created_at' },
          { label: 'Payment Method', key: 'payment_method' },
          { label: 'Total Payment', key: 'total_payment' },
          { label: 'Status', key: 'status' },
          { label: 'Actions', key: 'actions' }
        ],
        items: [],
        busy: false,
        status: 0,
        statusList: [
          { value: null, text: 'All' },
          { value: 0, text: 'Pending' },
          { value: 1, text: 'Success' },
          { value: 2, text: 'Rejected' }
        ],
        filterDate: formatDateV2(new Date()),
        rows: 0,
        perPage: 5,
        currentPage: 1,
        user: null
      },
      momo: {
        headers: [
          { label: 'Username', key: 'phone_number' },
          { label: 'Created At', key: 'created_at' },
          { label: 'Message', key: 'message' },
          { label: 'Total Payment', key: 'amount' },
          { label: 'Status', key: 'payment_status' }
          // { label: 'Actions', key: 'actions' }
        ],
        items: [],
        busy: false,
        status: 2,
        statusList: [
          { value: 0, text: 'All' },
          { value: 1, text: 'Success' },
          { value: 2, text: 'Failed' },
          { value: 3, text: 'Refunded' }
        ],
        filterDate: formatDateV2(new Date()),
        rows: 0,
        perPage: 5,
        currentPage: 1,
        user: null,
        userDebounce: null
      },
      withdraw: {
        headers: [
          { label: 'Username', key: 'username' },
          { label: 'Requested At', key: 'requested_at' },
          { label: 'Bank Info', key: 'withdraw_info', class: 'text-left' },
          { label: 'Total Payment', key: 'withdraw_amount' },
          { label: 'Note', key: 'note' },
          { label: 'Status', key: 'status' },
          { label: 'Actions', key: 'actions', clas: 'text-right' }
        ],
        items: [],
        busy: false,
        status: 0,
        statusList: [
          { value: null, text: 'All' },
          { value: 0, text: 'Pending' },
          { value: 1, text: 'Success' },
          { value: 2, text: 'Rejected' }
        ],
        filterDate: formatDateV2(new Date()),
        rows: 0,
        perPage: 5,
        currentPage: 1,
        user: null,
        userDebounce: null
      },
      reward: {
        headers: [
          { label: 'Username', key: 'username' },
          { label: 'Created At', key: 'created_at' },
          { label: 'Game', key: 'game_name' },
          { label: 'Product', key: 'product_name' },
          { label: 'Reward Title', key: 'reward_title' },
          { label: 'Reward', key: 'reward_amount' },
          { label: 'Status', key: 'status' }
        ],
        items: [],
        busy: false,
        status: 0,
        statusList: [
          { value: null, text: 'All' },
          { value: 0, text: 'Pending' },
          { value: 1, text: 'Success' },
          { value: 2, text: 'Rejected' }
        ],
        filterDate: formatDateV2(new Date()),
        rows: 0,
        perPage: 5,
        currentPage: 1,
        user: null,
        userDebounce: null
      },
      dpOptions: {
        format: 'MM/DD/YYYY',
        sideBySide: true
      }
    };
  },
  created() {
    this.getDepositData();
    this.getMomoData();
    this.getWithdrawData();
    this.getRewardData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Customer Support' }]);
  },
  computed: {
    ...mapGetters(['layoutConfig']),
    user() {
      return this.$store.getters.currentUser.data;
    }
  },
  methods: {
    approveWithdraw(item) {
      Swal.fire({
        title: 'Please confirm your approval!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Approve',
        confirmButtonColor: '#34bfa3',
        reverseButtons: true
      }).then((res) => {
        if (res.isConfirmed) {
          ApiService.put('withdraw-transactions/' + item.id, { status: 1 }).then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Payment Approved',
              showConfirmButton: false,
              timer: 3000
            }).then(() => {
              this.getWithdrawData();
            });
          });
        }
      });
    },
    rejectWithdraw(item) {
      Swal.fire({
        title: 'Please confirm your rejection!',
        icon: 'warning',
        showCancelButton: true,
        input: 'textarea',
        inputPlaceholder: 'Reject reason ...',
        confirmButtonText: 'Reject',
        confirmButtonColor: '#fd3995',
        reverseButtons: true
      }).then((res) => {
        if (res.isConfirmed) {
          ApiService.put('withdraw-transactions/' + item.id, { status: 2, note: res.value }).then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Payment Rejected',
              showConfirmButton: false,
              timer: 3000
            }).then(() => {
              this.getWithdrawData();
            });
          });
        }
      });
    },
    retryItem(item) {
      ApiService.post('/payments/retry', { deposit_id: item.display_deposit_id })
        .then((resp) => {
          if (resp.data.status) {
            Swal.fire({
              icon: 'success',
              title: 'Payment Updated',
              showConfirmButton: false,
              timer: 3000
            }).then(() => {
              this.getDepositData();
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Error occurs. Try again!',
            text: err,
            showConfirmButton: false,
            timer: 3000
          });
        });
    },
    activeDepositStatus(status) {
      return status.value == this.deposit.status;
    },
    changeDepositStatus(status) {
      this.deposit.status = status.value;
      this.deposit.currentPage = 1;
      this.getDepositData();
    },
    getDepositData() {
      const { from, to } = this.getFromTo(new Date(this.deposit.filterDate), 0);
      this.deposit.busy = true;
      ApiService.get(
        'deposit-transactions',
        `?username=${this.deposit.user || ''}&status=${
          this.deposit.status == null ? '' : this.deposit.status
        }&method_id=${this.filter_method_id || ''}&from=${from}&to=${to}&limit=${this.deposit.perPage}&offset=${
          this.deposit.currentPage
        }`
      )
        .then((resp) => {
          this.deposit.rows = resp.data.data.total;
          this.deposit.items = resp.data.data.depositTransactionList.map((deposit) => {
            return {
              ...deposit,
              created_at: formatDateForView(deposit.created_at)
            };
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: 'danger',
            title: 'Error occured',
            text: err,
            showConfirmButton: false,
            timer: 3000
          });
        })
        .finally(() => {
          this.deposit.busy = false;
        });
    },
    activeMomoStatus(status) {
      return status.value == this.momo.status;
    },
    changeMomoStatus(status) {
      this.momo.status = status.value;
      this.momo.currentPage = 1;
      this.getMomoData();
    },
    getMomoData() {
      const { from, to } = this.getFromTo(new Date(this.momo.filterDate), 0);
      this.momo.busy = true;
      ApiService.get(
        'payment/transaction-momo-history',
        `?username=${this.momo.user || ''}&status=${
          this.momo.status == null ? '' : this.momo.status
        }&from=${from}&to=${to}&limit=${this.momo.perPage}&offset=${this.momo.currentPage}`
      )
        .then((resp) => {
          this.momo.rows = resp.data.data.total;
          this.momo.items = resp.data.data.data.map((momo) => {
            return {
              ...momo,
              created_at: formatDateForView(momo.created_at)
            };
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: 'danger',
            title: 'Error occured',
            text: err,
            showConfirmButton: false,
            timer: 3000
          });
        })
        .finally(() => {
          this.momo.busy = false;
        });
    },
    activeWithdrawStatus(status) {
      return status.value == this.withdraw.status;
    },
    changeWithdrawStatus(status) {
      this.withdraw.status = status.value;
      this.withdraw.currentPage = 1;
      this.getWithdrawData();
    },
    getWithdrawData() {
      const { from, to } = this.getFromTo(new Date(this.withdraw.filterDate), 0);
      this.withdraw.busy = true;
      ApiService.get(
        'withdraw-transactions',
        `?username=${this.withdraw.user || ''}&status=${
          this.withdraw.status == null ? '' : this.withdraw.status
        }&payment_id=7&from=${from}&to=${to}&limit=${this.withdraw.perPage}&offset=${
          this.withdraw.currentPage
        }&requestid=`
      )
        .then((resp) => {
          this.withdraw.rows = resp.data.data.total;
          this.withdraw.items = resp.data.data.withdrawList.map((withdraw) => {
            return {
              ...withdraw,
              created_at: formatDateForView(withdraw.created_at)
            };
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: 'danger',
            title: 'Error occured',
            text: err,
            showConfirmButton: false,
            timer: 3000
          });
        })
        .finally(() => {
          this.withdraw.busy = false;
        });
    },
    activeRewardStatus(status) {
      return status.value == this.reward.status;
    },
    changeRewardStatus(status) {
      this.reward.status = status.value;
      this.reward.currentPage = 1;
      this.getRewardData();
    },
    getRewardData() {
      const { from, to } = this.getFromTo(new Date(this.reward.filterDate), 0);
      this.reward.busy = true;
      ApiService.get(
        'rewards',
        `?username=${this.reward.user || ''}&status=${this.reward.status == null ? '' : this.reward.status}&game=${
          this.game_id || ''
        }&from=${from}&to=${to}&limit=${this.reward.perPage}&offset=${this.reward.currentPage}&type=ticket`
      )
        .then((resp) => {
          this.reward.rows = resp.data.data.total;
          this.reward.items = resp.data.data.rewardList.map((reward) => {
            return {
              ...reward,
              created_at: formatDateForView(reward.created_at),
              username: reward.order.account.username,
              game_name: reward.game.name,
              status: reward.order_details[0].rewards[0].status,
              product_name: reward.order_details.map((od) => od.product.name).join('<br />'),
              reward_title: reward.order_details
                .map((od) => od.rewards.map((r) => r.product_reward.title).join('<br />'))
                .join('<br />')
            };
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: 'danger',
            title: 'Error occured',
            text: err,
            showConfirmButton: false,
            timer: 3000
          });
        })
        .finally(() => {
          this.reward.busy = false;
        });
    },
    getFromTo(date, num) {
      let from, to;
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59);
      to = new Date(date).toISOString();
      date.setDate(date.getDate() - num);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      from = new Date(date).toISOString();
      return { from, to };
    }
  }
};
</script>
